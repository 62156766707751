<template>
  <div class="mx-9 mb-8 insight-wrapper">
    <div class="d-flex px-5 py-3 insight">
      <span class="mr-3 font-weight-bold insight-title">Insight</span>
      <span class="font-color-dark">{{ details.text }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["details"]
};
</script>

<style lang="scss" scoped>
.insight-wrapper {
  margin-top: -6px;
}

.insight {
  background-color: #baddff;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.insight-title {
  color: #1263b3;
}
</style>
